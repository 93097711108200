<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

const { t } = useI18n()
const { currentUser } = storeToRefs(useCurrentUserStore())

const showProfileEditButton = true

</script>

<template>
  <div
    v-if="currentUser"
    class="group inline-flex items-center justify-center text-right"
  >
    <Menu
      v-slot="{ close }"
      as="div"
      class="relative size-9 text-left"
    >
      <MenuButton as="template">
        <button
          type="button"
          class="group-hover:ring-primary-500 dark:ring-offset-muted-900 inline-flex size-9 items-center justify-center rounded-full ring-1 ring-transparent transition-all duration-300 group-hover:ring-offset-4"
        >
          <div
            class="relative inline-flex size-9 items-center justify-center rounded-full"
          >
            <UserAvatar :key="currentUser.id" :user="currentUser" />
          </div>
        </button>
      </MenuButton>

      <Transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0"
      >
        <MenuItems
          class="divide-muted-100 border-muted-200 dark:divide-muted-700 dark:border-muted-700 dark:bg-muted-800 absolute end-0 z-50 mt-2 w-64 origin-top-right divide-y rounded-md border bg-white shadow-lg focus:outline-none"
        >
          
          <HeaderOrganizationSelect class="bg-muted-50/50 dark:bg-muted-950 p-6" />

          
          <div class="p-6 text-center">
            <UserAvatar :user="currentUser" size="xl" />

            <div v-if="currentUser" class="mt-3">
              <h6
                class="font-heading text-muted-800 text-sm font-medium dark:text-white"
              >
                {{ currentUser.firstName }}
              </h6>
              <p class="text-muted-400 mb-4 font-sans text-xs">
                {{ currentUser.email }}
              </p>
              <BaseButton
                v-if="showProfileEditButton"
                to="/profile-edit"
                shape="curved"
                class="w-full"
                @click.passive="close"
              >
                <Icon name="ph:user-circle-duotone" class="mr-1" />
                {{ t('manage') }}
              </BaseButton>
            </div>
          </div>

          
          <div class="p-6">
            <AuthSignoutButton class="w-full" @signout="close" />
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "logout": "Logout",
    "manage": "Manage account"
  },
  "nl": {
    "logout": "Uitloggen",
    "manage": "Beheer account"
  }
}
</i18n>
